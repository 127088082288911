<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.index)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>

    <RenameSortPlan ref="renameSortPlan" :refresher="initTableContent" />
    <SaveSortPlan
      :refresher="refresher"
      :updateSortPlanList="initTableContent"
      ref="save_sort_plan"
    />
    <ChangeSortTypeModal ref="changeSortType" :refresher="refresher" />
  </div>
</template>

<script>
import SwalService from "@/core/services/swal.service";
import RenameSortPlan from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/RenameSortPlan.vue";
import SaveSortPlan from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/SaveSortPlan.vue";
import ChangeSortTypeModal from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/ChangeSortTypeModal.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ActionsButton",
  components: { RenameSortPlan, SaveSortPlan, ChangeSortTypeModal },
  props: ["permissions", "selectedSortPlan", "refresher", "initTableContent"],
  data: () => ({}),
  methods: {
    handleClick(index) {
      if (this.selectedSortPlan) {
        if (index === 1) {
          this.handleEnable(this.selectedSortPlan);
        } else if (index === 2) {
          this.renameSortPlan(this.selectedSortPlan);
        } else if (index === 3) {
          this.showAddSortPlan();
        } else if (index === 4) {
          this.changeSortTypeAction();
        }
      } else {
        SwalService.warningMessage({
          title: this.$t("please_select_sort_plan"),
        });
      }
    },
    handleEnable(selectedSortPlan) {
      if (selectedSortPlan) {
        SwalService.warningConditionMessage(
          {
            html: this.$t("are_you_sure_to_enable_selected_chute_mapping"),
          },
          () => {
            this.$store.commit(SET_PAGE_LOADING, true);
            ApiService.post("/wes/wesChutes/status", {
              sort_plan_id: selectedSortPlan,
            })
              .then(async () => {
                SwalService.successMessage({
                  title: SwalService.titles.enabled,
                });
                await this.initTableContent();
                // await this.$store.dispatch(
                //   this.table_state.UPDATE_TABLE_DATA,
                //   {}
                // );
              })
              .catch(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
          }
        );
      } else {
        SwalService.warningMessage({
          title: this.$t("please_select_sort_plan"),
        });
      }
    },
    renameSortPlan(selectedSortPlan) {
      if (selectedSortPlan) {
        this.$refs.renameSortPlan.toggleModal(
          this.$store.getters.getWESCONFIGCHUTESSortPlans.find(
            (item) => item.id === selectedSortPlan
          )
        );
      } else {
        SwalService.warningMessage({
          title: this.$t("please_select_sort_plan"),
        });
      }
    },
    showAddSortPlan() {
      this.$refs.save_sort_plan.toggleModal();
    },
    changeSortTypeAction() {
      this.$refs.changeSortType.toggleModal();
    },
    permissionChecker(permType) {
      return this.table_permissions.includes(permType);
    },
  },
  computed: {
    table_permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      if (this.$store.getters.getWESCONFIGCHUTESTablePermissions) {
        permissions =
          this.$store.getters.getWESCONFIGCHUTESTablePermissions.map(function (
            perm
          ) {
            return perm.name;
          });
      }
      return permissions;
    },
    types: function () {
      const types = [
        { index: 1, name: this.$t("enable"), perm: "status" },
        { index: 2, name: this.$t("edit_sort_plan"), perm: "save_sort_plan" },
        { index: 3, name: this.$t("add_sort_plan"), perm: "save_sort_plan" },
        {
          index: 4,
          name: this.$t("change_sort_type"),
          perm: "change_sort_type",
        },
      ];

      return types.filter((item) => this.permissions(item.perm));
    },
  },
};
</script>

<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}

.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
