<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ $t("save_sort_plan") }}</h3>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="row">
          <div class="h-75px col-12">
            <v-text-field
              dense
              outlined
              v-model="formData.name"
              :label="$t('name')"
              clearable
            />
          </div>

          <!--end::Body-->

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mx-3 px-5 py-3 ls1"
              @click="resetCreateForm"
            >
              {{ $t("clear") }}
            </button>
            <button
              type="submit"
              class="btn btn--export-filter px-5 py-3 ls1"
              @click="submitCreateForm"
            >
              {{ $t("submit") }}
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  mixins: [],
  name: "SaveSortPlan",
  props: ["refresher", "updateSortPlanList"],
  data: () => ({
    dialog: false,
    formData: {
      name: "",
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/wes/wesChutes/save_sort_plan", this.formData)
        .then(() => {
          SwalService.successMessage({
            html: SwalService.messages.added(),
          });
          // SwalService.successMessage({
          //   title: SwalService.titles.created,
          //   html: SwalService.messages.created(),
          // });
          this.toggleModal();
          this.updateSortPlanList();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.formData = {
        name: null,
      };
    },
  },
  computed: {},
};
</script>
