<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ $t("edit_item") }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>

      <div
        v-if="$store.getters.getWESCONFIGCHUTESCategories.length"
        class="modal-body d-flex flex-column scroll-y mx-5 py-7 d-flex flex-wrap poppins"
      >
        <div class="w-100 px-3 h-75px">
          <v-autocomplete
            v-model="service_type_id"
            label="Service type"
            :items="types"
            item-text="text"
            item-value="index"
            dense
            outlined
            clearable
            hide-no-data
            hide-selected
            :error-messages="service_type_idErrors"
            @blur="$v.service_type_id.$touch()"
          />
        </div>
        <div class="w-100 d-flex flex-row flex-wrap justify-content-between">
          <NestedDataTable
            :tableName="$t('locations')"
            :handleLocationSelect="handleLocationSelect"
          />
          <NestedDataTable :tableName="$t('categories')" />
        </div>
        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <!--          <button-->
          <!--            type="reset"-->
          <!--            class="btn btn-light mr-3 px-5 py-3 ls1"-->
          <!--            @click="resetFormData"-->
          <!--          >-->
          <!--            Clear-->
          <!--          </button>-->
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import NestedDataTable from "@/own/components/warehouseExecutionSystem/WESConfigurationChutes/NestedDatatable.vue";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  CHUTES_SET_CATEGORIES,
  CHUTES_SET_LOCATIONS,
  // CHUTES_SET_MAPPINGS,
  CHUTES_SET_SELECTED_LOCATION_INDEX,
} from "@/core/services/store/wesConfigurationChutes.module";
import SwalService from "@/core/services/swal.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  components: { NestedDataTable },
  mixins: [validationMixin],
  props: ["refresher", "pageLoader"],
  validations() {
    return {
      service_type_id: { required },
    };
  },
  data: () => ({
    dialog: false,
    service_type_id: false,
    types: [],
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        //   this.resetFormData();
        // } else {
        this.loadDataFromServer();
      } else this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      let sendData = {
        id: this.$store.getters.getItemForAction.id,
        sort_plan_id: this.$store.getters.getWESCONFIGCHUTESSelectedSortPlan,
      };
      this.pageLoader(true);
      ApiService.post("/wes/wesChutes/edit", sendData)
        .then(({ data }) => {
          // console.log("data", data);
          this.types = data.types;
          this.service_type_id = data.data.service_type_id;
          this.$store.commit(CHUTES_SET_LOCATIONS, {
            locations: data.locations,
            mappings: data.data.mapping,
          });
          this.$store.commit(CHUTES_SET_CATEGORIES, data.categories);
          // this.$store.commit(CHUTES_SET_MAPPINGS, data.data.mapping);

          this.pageLoader(false);
          this.dialog = !this.dialog;
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    toggleNextButton(id, event, tableName) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;

      elem.classList.add("bg-light-info");
      elem.firstElementChild.firstElementChild.style.color = "#8950fc";

      const rows = document.querySelectorAll(
        `.${tableName}-tb > .list-container  > tr > td.d-flex .bg-light-info`
      );
      rows.forEach((element) => {
        if (!element.classList.contains(`${tableName}-tb-${id}-next-btn`)) {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        }
      });

      this.$store.commit(SET_PAGE_LOADING, false);
    },
    handleLocationSelect(selectedLocationId, event) {
      this.$store.commit(SET_PAGE_LOADING, true);

      const location_index =
        this.$store.getters.getWESCONFIGCHUTESMappings.findIndex(
          (location) => location.id === selectedLocationId
        );

      this.$store.commit(CHUTES_SET_SELECTED_LOCATION_INDEX, location_index);

      if (location_index > -1) {
        this.$store.getters.getWESCONFIGCHUTESCategories.forEach((category) => {
          category.is_selected = Boolean(
            this.$store.getters.getWESCONFIGCHUTESMappings[
              location_index
            ].categories.find((item) => item === category.index)
          );
        });
      }
      this.toggleNextButton(selectedLocationId, event, "locations");
    },
    // resetFormData() {
    // this.formData = {
    //   connection_id: null,
    //   name: null,
    //   is_reject: false,
    //   sorting_locations: [],
    //   zone_id: null,
    //   description: null,
    // };
    // },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        id: this.$store.getters.getItemForAction.id,
        sort_plan_id: this.$store.getters.getWESCONFIGCHUTESSelectedSortPlan,
        service_type: this.service_type_id,
        locations: this.$store.getters.getWESCONFIGCHUTESMappings.map(
          (mapping) => ({ ...mapping })
        ),
        // locations: this.$store.getters.getWESCONFIGCHUTESMappings.map(
        //   (mapping) => {
        //     return {
        //       id: mapping.id,
        //       categories: mapping.categories.map((item) => item.id),
        //     };
        //   }
        // ),
      };
      // console.log(payload);
      // return;

      ApiService.post(`/wes/wesChutes/update`, payload)
        .then(() => {
          SwalService.successMessage({
            html: SwalService.messages.added(),
          });
          this.refresher();
          this.dialog = false;
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v[fieldName].$dirty) return errors;
      if ("required" in this.$v[fieldName]) {
        !this.$v[fieldName].required && errors.push("This field is required");
      }
      return errors;
    },
  },
  computed: {
    service_type_idErrors: function () {
      return this.handleFormValidation("service_type_id");
    },
  },
};
</script>
